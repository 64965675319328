import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex !important',
        flexDirection: 'column !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        height: '100vh !important',
        textAlign: 'center !important',
    },
    title: {
        fontWeight: 'bold !important',
        color: '#00796B !important',
        fontFamily: 'Poppins, sans-serif !important',
        marginBottom: '20px !important',
        fontSize: '24px !important',
    },
    subtitle: {
        color: '#00796B !important',
        fontFamily: 'Poppins, sans-serif !important',
        marginBottom: '40px !important',
        fontSize: '18px !important',
    },
    button: {
        backgroundColor: '#009688 !important',
        color: '#ffffff !important',
        padding: '12px 20px !important',
        borderRadius: '30px !important',
        textTransform: 'none !important',
        fontWeight: 'bold !important',
        fontFamily: 'Poppins, sans-serif !important',
        transition: 'background-color 0.3s ease !important',
        '&:hover': {
            backgroundColor: '#00796B !important',
        },
    },
    image: {
        width: '200px !important',
        height: '200px !important',
        marginBottom: '40px !important',
    },
}));

const SuccessPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate('/');
    };

    return (
        <Box className={classes.container}>
            <img src="img/cat.jpeg" alt="Success" className={classes.image} />
            <Typography variant="h4" className={classes.title}>
                Booking Confirmed!
            </Typography>
            <Typography variant="h6" className={classes.subtitle}>
                Your booking has been successfully completed.
            </Typography>
            <Button variant="contained" className={classes.button} onClick={handleGoBack}>
                Go to Homepage
            </Button>
        </Box>
    );
};

export default SuccessPage;
