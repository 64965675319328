import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    image: {
        width: '150px',
        height: '150px',
        marginBottom: '20px',
    },
    progressBar: {
        width: '80%',
        maxWidth: '600px',
        marginBottom: '20px',
    },
    loadingText: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 'bold',
        color: '#00796B',
    },

}));

const Loading = () => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <img src="img/loading-cat.png" alt="Loading" className={classes.image} />
            <LinearProgress className={classes.progressBar} />
            <Typography variant="h6" className={classes.loadingText}>
                Loading...
            </Typography>
        </Box>
    );
};

export default Loading;
