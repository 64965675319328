import React from 'react';
import { Container, Typography, Grid, Link, Paper, Box, Card, CardContent, Fab, styled } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // Smooth scrolling
  });
};

const galleryImages = [
  'img/index_cards/portuguese_villa.jpg',
  'img/index_cards/casa_mellow.jpg',
  'img/index_cards/pebbles.jpg',
  'img/index_cards/honeydew.jpg',
  'img/index_cards/harmony.jpg',
  'img/index_cards/natures_haven.jpg',
];

const StyledFooter = styled(Box)(({ theme }) => ({
  backgroundColor: '#014d4e',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  marginTop: theme.spacing(5),
  color: '#f6f6e9',
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 0,
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2),
  },
}));

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: '#00796b',
  '&:hover': {
    backgroundColor: '#004d40',
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: '#f6f6e9',
  display: 'block',
  marginBottom: theme.spacing(1),
  '&:hover': {
    textDecoration: 'none',
    color: '#cfd8dc',
  },
}));

const SocialIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  '& > *': {
    marginRight: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
}));

const Footer = () => (
  <StyledFooter>
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <StyledCard>
            <CardContent>
              <Typography variant="h4" gutterBottom>Company</Typography>
              <StyledLink href="/about">About Us</StyledLink>
              <StyledLink href="/contactus">Contact Us</StyledLink>
              <StyledLink href="/privacypolicy">Privacy Policy</StyledLink>
              <StyledLink href="/terms-conditions">Terms And Conditions</StyledLink>
              <StyledLink href="/refund-policy">Cancellation And Refund Policy</StyledLink>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledCard>
            <CardContent>
              <Typography variant="h4" gutterBottom>Contact</Typography>
              <Box sx={{ mb: 2 }}>
                <LocationOnIcon sx={{ mr: 1 }} />
                <Typography component="span">Opposite Luna's ristorante, Starco junction, Anjuna, Goa - 403509</Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <EmailIcon sx={{ mr: 1 }} />
                <Typography component="span">goaroundhomes@gmail.com</Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <PhoneIcon sx={{ mr: 1 }} />
                <Typography component="span">+91 7210238757</Typography>
              </Box>
              <SocialIcons>
                <StyledLink href="https://twitter.com/GoaroundHomes" underline="none">
                  <TwitterIcon />
                </StyledLink>
                <StyledLink href="https://www.facebook.com/goaroundhomes/" underline="none">
                  <FacebookIcon />
                </StyledLink>
                <StyledLink href="https://api.whatsapp.com/send/?phone=%2B917210238757&text&type=phone_number&app_absent=0" underline="none">
                  <WhatsAppIcon />
                </StyledLink>
                <StyledLink href="https://www.linkedin.com/company/goaround-homes/?viewAsMember=true" underline="none">
                  <LinkedInIcon />
                </StyledLink>
              </SocialIcons>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledCard>
            <CardContent>
              <Typography variant="h4" gutterBottom>Gallery</Typography>
              <Grid container spacing={1}>
                {galleryImages.map((imgSrc, index) => (
                  <Grid item xs={4} key={index}>
                    <Paper variant="outlined" sx={{ bgcolor: 'light', p: 1 }}>
                      <img loading='lazy' src={imgSrc} alt={`Gallery ${index}`} style={{ width: '100%', height: 'auto' }} />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Container>
    <StyledFab color="primary" size="large" onClick={scrollToTop}>
      <KeyboardArrowUpIcon />
    </StyledFab>
  </StyledFooter>
);

export default Footer;
