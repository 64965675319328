import React from 'react';
import { Button, Menu, MenuItem, ListItem, ListItemIcon, Typography } from '@mui/material';
import { HouseDoorFill, Building, Water, TreeFill, Tree, YinYang, DropletHalf, HouseHeartFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';


const destinations = [
    {
        name: 'North Goa',
        icon: <HouseDoorFill />,
        subDestinations: [
            { name: 'Portuguese Villa', icon: <HouseDoorFill />, path: '/portuguese-villa' },
            { name: 'Neo Villas', icon: <Building />, path: '/neo-villas' },
            { name: 'Casa Mellow', icon: <Water />, path: '/casa-mellow' },
            { name: 'Kiara Homestay', icon: <Building />, path: '/kiara-homestay' },
            { name: 'Honeydew Suits & Villas', icon: <HouseDoorFill />, path: '/honeydew-suits-villas' },
            { name: "Nature's Haven", icon: <TreeFill />, path: '/natures-haven' },
            { name: 'Zen Den', icon: <Tree />, path: '/zen-den' },
            { name: 'Harmony Villa', icon: <YinYang />, path: '/harmony-villa' },
            { name: 'Pebbles', icon: <DropletHalf />, path: '/pebbles-villa' }
        ]
    },
    {
        name: 'New Delhi',
        icon: <HouseHeartFill />,
        subDestinations: [
            { name: "Atharva's Homestay", icon: <HouseHeartFill />, path: '/atharvas-homestay' }
            // Add more sub destinations for New Delhi if needed
        ]
    }
];

function DropdownMenu({ handleMenuClose }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <MenuItem
                id="dropdownMenuButton1"
                aria-controls="dropdown-menu"
                aria-expanded={anchorEl ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{
                    fontSize: '16px !important',
                    color: '#14a49f !important',
                    fontFamily: 'Arial, sans-serif !important',
                    fontWeight: '500 !important',
                    textTransform: 'none !important',
                    '&:hover': {
                        backgroundColor: 'rgba(20, 164, 159, 0.04) !important',
                        color: '#117a78 !important',
                    },
                }}
            >
                Destinations
            </MenuItem>
            <Menu
                id="dropdown-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'dropdownMenuButton1',
                }}
            >
                {destinations.map((destination, index) => (
                    <div key={index}>
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon sx={{ color: '#14a49f !important' }}>{destination.icon}</ListItemIcon>
                            <Typography variant="inherit">{destination.name}</Typography>
                        </MenuItem>
                        {destination.subDestinations.map((subDestination, subIndex) => (
                            <ListItem key={subIndex} button onClick={handleClose}>
                                {subDestination.icon && <ListItemIcon>{subDestination.icon}</ListItemIcon>}
                                <Link to={subDestination.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Typography variant="inherit">
                                        {subDestination.name}
                                    </Typography>
                                </Link>
                            </ListItem>
                        ))}
                    </div>
                ))}
            </Menu>
        </div>
    );
}

export default DropdownMenu;
