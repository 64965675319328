import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import SuccessPage from './SuccessPage';
import propertiesData from './input.json';
import Loading from './Loading';

const Home = lazy(() => import('./Home'));
const PropertyPage = lazy(() => import('./PropertyPage'));
const AboutPage = lazy(() => import('./AboutPage'));
const Packages = lazy(() => import('./Packages'));
const BlogPage = lazy(() => import('./BlogPage'));
const ContactUs = lazy(() => import('./ContactUs'));
const BookingForm = lazy(() => import('./BookingForm'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const ResultsPage = lazy(() => import('./ResultsPage'));
const BookingModel = lazy(() => import('./BookingModel'));
const TermsConditions = lazy(() => import('./TermsConditions'));
const CancellationAndRefundPolicy = lazy(() => import('./CancellationAndRefundPolicy'));

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            {propertiesData.Properties.map((property) => (
              <Route
                key={property.path}
                path={property.path}
                element={<PropertyPage property={property} />}
              />
            ))}
            <Route path="/about" element={<AboutPage />} />
            <Route path="/packages" element={<Packages data={propertiesData.Properties} />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/booking" element={<BookingModel />} />
            <Route path="/results" element={<ResultsPage packageData={propertiesData.Properties} />} />
            <Route path="/booking-form" element={<BookingForm />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/refund-policy" element={<CancellationAndRefundPolicy />} />
            <Route path="/success" element={<SuccessPage />} />
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
