import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, MenuItem, Popover, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DropdownMenu from './DropdownMenu';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

// Improved styling
const useStyles = makeStyles((theme) => ({
    customHeading: {
        fontWeight: '600 !important',
        color: '#14a49f !important',
        fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important',
    },
    customText: {
        fontSize: '16px !important',
        color: '#14a49f !important',
        fontFamily: 'Arial, sans-serif !important',
        fontWeight: '500 !important',
        textTransform: 'none !important',
        '&:hover': {
            backgroundColor: 'rgba(20, 164, 159, 0.04) !important',
            color: '#117a78 !important',
        },
    },
    appBar: {
        boxShadow: 'none !important',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12) !important',
        backgroundColor: '#ffffff !important',
    },
    toolbar: {
        minHeight: '64px !important',
    },
    menuButton: {
        color: '#14a49f !important',
    },
    logo: {
        height: '40px', // Adjust the height of the logo as needed
        marginTop: '2px'
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',

    },
}));

function Navbar() {
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles(); // Initialize the custom styles

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="sticky" className={classes.appBar}>
            <Toolbar className={classes.toolbar} >
                {isMobile ? (
                    <>
                        <div className={classes.headerContainer}>

                            <IconButton size="large" edge="start" aria-label="menu" className={classes.menuButton} onClick={handleMenuClick}>
                                <MenuIcon />
                            </IconButton>
                            <Typography align="left" variant="h7" component="div" sx={{ flexGrow: 1 }} className={classes.customHeading}>

                                Goaround Homes
                            </Typography>
                            <Popover
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem component={Link} to="/" className={classes.customText} onClick={handleMenuClose}>Home</MenuItem>
                                <DropdownMenu handleMenuClose={handleMenuClose} />
                                <MenuItem component={Link} to="/about" className={classes.customText} onClick={handleMenuClose}>About</MenuItem>
                                <MenuItem component={Link} to="/packages" className={classes.customText} onClick={handleMenuClose}>Packages</MenuItem>
                                <MenuItem component={Link} to="/blog" className={classes.customText} onClick={handleMenuClose}>Blog</MenuItem>
                                <MenuItem component={Link} to="/contactus" className={classes.customText} onClick={handleMenuClose}>Contact</MenuItem>

                                {/* <MenuItem component={Link} to="/book" className={classes.customText} onClick={handleMenuClose}>Book</MenuItem> */}
                            </Popover>
                            <img src={'img/logo.svg'} alt="Goaround Homes Logo" className={classes.logo} />
                        </div >
                    </>
                ) : (
                    <>
                        <img src={'img/logo.svg'} alt="Goaround Homes Logo" className={classes.logo} />
                        <Typography align="left" variant="h6" component="div" sx={{ flexGrow: 1 }} className={classes.customHeading}>

                            Goaround Homes
                        </Typography>
                        <Button component={Link} to="/" className={classes.customText}>Home</Button>
                        <DropdownMenu handleMenuClose={handleMenuClose} />
                        <Button component={Link} to="/about" className={classes.customText}>About</Button>
                        <Button component={Link} to="/packages" className={classes.customText}>Packages</Button>
                        <Button component={Link} to="/blog" className={classes.customText}>Blog</Button>
                        <Button component={Link} to="/contactus" className={classes.customText}>Contact</Button>


                        {/* <Button component={Link} to="/book" className={classes.customText}>Book</Button> */}

                    </>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;
